@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
    /* -ms-overflow-style: none; 
    scrollbar-width: none;
    -webkit-scrollbar:none; */
  }
  
  body {
    font-family: "Lexend","inter", sans-serif;
  }
  
  @font-face {
    font-family: jeju;
    src: url(../public/fonts/JejuGothic-Regular.ttf);
  }
  .jeju {
      font-family: jeju;
  }
  @font-face {
    font-family: sfPro;
    src: url(../public/fonts/sfPro.ttf);
  }
  .sfPro {
      font-family: sfPro;
  }
  @font-face {
    font-family: hebrew;
    src: url(../public/fonts/Open\ Sans\ Hebrew\ Bold.ttf);
  }
  .hebrew {
      font-family: hebrew;
  }
  
  @layer utilities {
  
    .maxContainer {
      @apply mx-auto max-w-[1280px];
    }
  
  
    .flexCenter {
      @apply flex items-center justify-center;
    }
  
    .flexBetween {
      @apply flex items-center justify-between;
    }
  
    .flexStart {
      @apply flex items-center justify-start;
    }
  
    .flexEnd {
      @apply flex items-center justify-end;
    }
  }
  
